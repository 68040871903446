import { APP_STORE_SCORE, PLAY_STORE_SCORE } from '@/lib/constants'
import supabaseClient from '@/lib/supabaseClient'
import { Button, Fab, Rating, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function DownloadAppBanner() {
  const { t } = useTranslation()
  const [visibility, setVisibility] = useState<number>(90)
  const [synivaScore, setSynivaScore] = useState<number>()
  const [isWebView, setIsWebView] = useState<boolean>(false)

  const getSynivaScore = async () => {
    const { data } = await supabaseClient
      .from('variables')
      .select('value')
      .eq('name', ['iphone', 'iphone', 'safari'].includes(window.navigator.userAgent.toLowerCase()) ? APP_STORE_SCORE : PLAY_STORE_SCORE)
      .single()
    setSynivaScore(data?.value)
  }

  useEffect(() => {
    getSynivaScore()
    checkifIsWebView()
  },[])

  const checkifIsWebView = () => {
    const cookies = document.cookie.split(';')
    const versionToCheck = cookies.find((cookie) => cookie.includes('syniva-app-version'))
    const version = versionToCheck?.split('=')[1]
    if (version && version !== '') {
      setIsWebView(true)
    }
  }

  if (isWebView) return null

  return (
    <div style={{
      position: 'fixed',
      backgroundColor: 'white',
      width: `${visibility}%`,
      height: '5.2rem',
      paddingTop: '0.5rem',
      paddingLeft: '0.5rem',
      borderEndEndRadius: '10px',
      borderTopRightRadius: '10px'
    }}>
      {(visibility > 50) ? (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ display: 'flex', width: '60%', marginBottom: '0.5rem' }}>
            <img src="/images/Syniva-App-Logo.jpg" alt="App-Logo" width={50} />
            <div style={{ marginLeft: '0.5rem', display: 'grid' }}>
              <Typography style={{ fontSize: '14px', marginTop: '2px' }}>{t('mobileBanner.bannerText')}</Typography>
              <Rating key={synivaScore} name="read-only-score" value={synivaScore} precision={0.5} readOnly />
            </div>
          </div>
          <div style={{ width: '40%', height: '2.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{ marginTop: '0.5rem', fontSize: '12px', minWidth: '80%', color: '#0e046d', backgroundColor: '#c3fb00', fontWeight: 'bold' }}
              onClick={() => {location.href = 'https://onelink.to/syniva'}}>
                {t('mobileBanner.downloadAppText')}
            </Button>
            <Button variant='text' style={{ marginTop: '0.5rem', minWidth: '20%', fontWeight: 'bold', fontSize: '15px', color: '#0e046d' }} onClick={() => {setVisibility(10)}}>X</Button>
          </div>
        </div>
        <b style={{ display: 'flex', marginTop: '-0.4rem', fontSize: '10px' }}>{t('mobileBanner.infoText')}</b>
      </>) : <div>
        <Button variant='text' style={{ marginTop: '0.3rem', minWidth: '20%', fontWeight: 'bold', fontSize: '20px', color: '#0e046d' }} onClick={() => {setVisibility(90)}}>{t('mobileBanner.expandIcon')}</Button>
      </div>}
    </div>
  )
}
