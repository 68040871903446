import { Swiper, SwiperSlide } from 'swiper/react'
import { Container } from '@mui/system'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import style from './LogosCarousel.module.css'
import { Autoplay, Pagination, Navigation } from 'swiper'
import { useEffect, useState } from 'react'
import { Marketplace } from '@/types/supabase.types'
import { getMarketplaces } from '@/lib/supabaseApi'
import { Box, useMediaQuery } from '@mui/material'
import DownloadAppBanner from '../mobile/downloadAppBanner'
import { MOBILE_WIDTH_LIMIT } from '@/lib/constants'

export default function LogosCarousel() {
  const [marketplaces, setMarketPlaces] = useState<Marketplace[] | null>()
  const isMobile = useMediaQuery(`(max-width:${MOBILE_WIDTH_LIMIT}px)`)

  useEffect(() => {
    getMarketplaces().then((marketplaces) => {
      if (!marketplaces) return

      const final = marketplaces.filter((mk: Marketplace) => mk.is_in_home) as Marketplace[]
      setMarketPlaces(final)
    })
  }, [])

  return (<Container className={style.container}>
    {(isMobile) && <div style={{ position: 'absolute', bottom: 0, marginBottom: '75%', marginLeft: '-1rem', zIndex: '1'}}>
      <DownloadAppBanner />
    </div>}
    {
      marketplaces && <Swiper
        slidesPerView={'auto'}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={70}
        modules={[Autoplay, Pagination, Navigation]}
        className={style.swiper}
      >
        {marketplaces?.map((marketplace, index) => {
          return (
            <SwiperSlide key={index} className={style.slide} style={{
              backgroundImage: `url("${marketplace.logo_url}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center'
            }}>
            <a href={marketplace.url || '#'} style={{width: '100%', height: '100%', cursor: 'pointer'}}>
              <Box />
            </a>
            </SwiperSlide>
          )
        })}
      </Swiper >
    }
  </Container>
  )
}
